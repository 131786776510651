import router from '../router'
import { AuthService, type Authentication } from '../services/authService'
import { ProjectService } from '@inhaus-ai-limited/project-api-sdk'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useFilterStore } from './filters'

const authService = new AuthService()

export const useAuthStore = defineStore('auth', () => {
    let tokenRefreshTimer: NodeJS.Timeout | undefined
    const authentication = ref<Authentication>({
        name: '',
        email: '',
        role: '',
        teamId: '',
        jwt: {
            token: '',
            expiresAt: 0,
        },
    })

    // Getters
    const isAuthenticated = computed(() => {
        if (localStorage.getItem('authorization')) {
            try {
                const parsedData = JSON.parse(localStorage.getItem('authorization') || '{}')
                updateAuthenticationState(parsedData)
            } catch (error) {
                localStorage.removeItem('authorization')
            }
        }
        return authentication.value.jwt.token !== '' && authentication.value.jwt.expiresAt > new Date().getTime()
    })
    const name = computed(() => authentication.value.name)
    const email = computed(() => authentication.value.email)
    const jwt = computed(() => authentication.value.jwt)
    const token = computed(() => authentication.value.jwt.token)
    const role = computed(() => authentication.value.role)

    // Actions
    const signIn = async (email: string, password: string): Promise<Authentication> => {
        const auth = await authService.authenticate(email, password)
        console.log('authCheck', auth)
        updateAuthenticationState(auth)
        setupTokenRefresh()
        localStorage.setItem('authorization', JSON.stringify(authentication.value))

        return auth
    }

    const signOut = (automated: Boolean): void => {
        localStorage.clear()

        // Clear session storage
        sessionStorage.clear()

        // Clear cookies
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        clearTokenRefreshTimer()
        resetAuthenticationState()
        localStorage.removeItem('authorization')
        if (automated) {
            router.push({ name: 'signin', query: { redirect: router.currentRoute.value.fullPath } })
        } else {
            window.location.href = '/signin';
            
            // window.location.reload(); // Forces the browser to reload without using cache
        }
    }

    const updateAuthenticationState = (auth: Authentication): void => {
        console.log('updateAuthenticationState')
        authentication.value = auth
        console.log('checking authentication', authentication.value)
    }

    const resetAuthenticationState = (): void => {
        authentication.value = {
            name: '',
            email: '',
            role: '',
            teamId: '',
            jwt: { token: '', expiresAt: 0 },
        }
    }

    const setupTokenRefresh = (): void => {
        clearTokenRefreshTimer()
        const expiresIn = authentication.value.jwt.expiresAt - new Date().getTime()
        if (expiresIn > 0) {
            tokenRefreshTimer = setTimeout(async () => {
                console.log('checking token refresh')
                try {
                    const newToken = await authService.refreshToken()
                    authentication.value.jwt.token = newToken
                    authentication.value.jwt.expiresAt = new Date().getTime() + 60 * 60 * 1000 // Assuming 1 hour token validity
                    setupTokenRefresh() // Reset the timer with the new expiration time
                    localStorage.setItem('authorization', JSON.stringify(authentication.value))
                    console.log('checking token refresh,it worked')
                } catch (error) {
                    console.error('Error refreshing token:', error)
                    signOut(false)
                }
            }, expiresIn - 1000 * 60 * 5) // Refresh 5 minutes before expiration
        }
    }

    const clearTokenRefreshTimer = (): void => {
        if (tokenRefreshTimer !== undefined) {
            clearTimeout(tokenRefreshTimer)
            tokenRefreshTimer = undefined
        }
    }

    // Return
    return {
        authentication,
        isAuthenticated,
        name,
        email,
        jwt,
        token,
        role,
        signIn,
        signOut,
        setupTokenRefresh,
    }
})
